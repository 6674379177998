import React, { Fragment, PropsWithChildren, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { RoleType, useMeQuery } from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { useAuthStore } from "~shared/stores/auth";
import { LoginPageRoute } from "~shared/routes";

export const ProtectedRoutes: React.FC<PropsWithChildren> = ({ children }) => {
  const token = useAuthStore((state) => state.token);

  const unAuth = useAuthStore((state) => state.unAuth);

  const client = useGraphqlClient();

  const { isSuccess, data } = useMeQuery(client);

  const isAdmin = data?.me?.role === RoleType.Admin;

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    if (!isAdmin) {
      unAuth();
    }
  }, [unAuth, isSuccess, isAdmin]);

  if (!isSuccess) {
    return (
      <Box className='w-full h-full flex items-center justify-center'>
        <CircularProgress />
      </Box>
    );
  }

  if (!token || !isAdmin) {
    return <Navigate to={LoginPageRoute} replace />;
  }

  return <Fragment>{children}</Fragment>;
};
