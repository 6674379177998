import { SyntheticEvent, useEffect, useState } from "react";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { User, useUsersQuery } from "~/generated/graphql";

export const useSelect = (onFormChange: (event: number) => void) => {
  const [value, setValue] = useState<User | null>(null);
  const [options, setOptions] = useState<Record<string | number, User> | null>(null);
  const [page, setPage] = useState<number>(1);

  const client = useGraphqlClient();

  const { data: allData, isLoading } = useUsersQuery(
    client,
    { page, filter: [{ column: "telegram_idNot", value: null }] },
    {
      refetchOnMount: "always"
    }
  );

  const values = allData?.users?.data;

  const paginatorInfo = allData?.users?.paginatorInfo;

  const handleChange = (event: SyntheticEvent, newValue: User | null) => {
    const eventTarget = event.target as unknown as User;

    onFormChange(Number(eventTarget.id));
    setValue(newValue);
  };

  const handleScroll = (event: SyntheticEvent) => {
    const node = event.currentTarget;
    const position = node.scrollTop + node.clientHeight;
    const isNextPage =
      paginatorInfo && page < paginatorInfo.lastPage && node.scrollHeight - position <= 1;

    if (isNextPage) {
      setPage((cur) => cur + 1);
    }
  };

  useEffect(() => {
    if (values) {
      const newContests = values.reduce((res, cur) => {
        res[cur.id ?? ""] = cur;
        return res;
      }, Object.create(null));

      setOptions((cur) => ({ ...cur, ...newContests }));
    }
  }, [values]);

  return {
    value,
    handleChange,
    options: Object.values(options ?? {}),
    handleScroll,
    isLoading
  };
};
