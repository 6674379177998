import React, { Fragment } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress, Fade, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { LoginPageRoute, SettingsPageRoute, ProfilePageEdit } from "~shared/routes";
import { Text } from "~shared/components/Text";
import { Button } from "~/shared/components/Button";
import { useAuthStore } from "~/shared/stores/auth";
import { useMeQuery } from "~/generated/graphql";
import { useGraphqlClient } from "~/app/providers/GraphqlClient";
import { Link } from "~/shared/components/Link";

const links = [
  {
    label: "Настройки",
    path: SettingsPageRoute
  }
];

export const HeaderProfile: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const id = open ? "header-profile" : undefined;

  const history = useNavigate();

  const client = useGraphqlClient();

  const { data } = useMeQuery(client);

  const user = data?.me;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const unAuth = useAuthStore((state) => state.unAuth);

  const loaderVisible = false;

  const handleLogoutClick = () => {
    unAuth();
    history(LoginPageRoute);
  };

  return (
    <Fragment>
      <Button className='flex' onClick={handleClick}>
        {loaderVisible && <CircularProgress />}

        <ExpandMoreIcon
          className={clsx("transition-transform duration-500 ease-in-out transform text-mainText", {
            "rotate-180": open,
            "rotate-0": !open
          })}
        />
      </Button>
      <Menu
        id={id}
        MenuListProps={{
          "aria-labelledby": id
        }}
        className='!w-full'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem className='!p-0'>
          <Link className='w-full px-4 py-2' to={ProfilePageEdit.replace(":id", String(user?.id))}>
            <Text>Профиль</Text>
          </Link>
        </MenuItem>

        {links.map((link) => (
          <MenuItem className='!p-0' key={link.label}>
            <Link className='w-full px-4 py-2' to={link.path}>
              <Text>{link.label}</Text>
            </Link>
          </MenuItem>
        ))}
        <MenuItem className='!p-0'>
          <Button onClick={handleLogoutClick} className='w-full !justify-start !px-4 !py-2'>
            Выйти
          </Button>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
