import React, { PropsWithChildren } from "react";
import { Box, IconButton, Modal as MuiModal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Text } from "../Text";

type Props = {
  open: boolean;
  title?: string;
  onClose: () => void;
};

export const Modal: React.FC<PropsWithChildren<Props>> = ({ open, title, onClose, children }) => {
  return (
    <MuiModal open={!!open} onClose={onClose} aria-labelledby='modal-title'>
      <Box className='flex flex-col bg-bg-light absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] w-full lg:w-[900px] max-h-[90vh] min-h-[300px] rounded-lg overflow-auto'>
        <Box className='flex items-center justify-between p-6'>
          <Text id='control-modal-title' className='font-bold text-xl'>
            {title ?? ""}
          </Text>

          <IconButton onClick={onClose} size='small' className='!min-w-fit'>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Box>
    </MuiModal>
  );
};
