import React from "react";
import { clsx } from "clsx";
import PsychologyIcon from "@mui/icons-material/Psychology";

type Props = {
  size?: "small" | "big";
  className?: string;
  isSizeClass?: boolean;
};

export const Logo: React.FC<Props> = ({ size, className, isSizeClass }) => {
  return (
    <PsychologyIcon
      className={clsx(
        "shrink-0",
        {
          "w-[35px] h-[35px]": !size && !isSizeClass,
          "w-[50px] h-[50px]": size === "big",
          "w-[25px] h-[25px]": size === "small"
        },
        className
      )}
    />
  );
};
