import React, { FocusEventHandler, Fragment } from "react";
import { Autocomplete, Box, CircularProgress, MenuItem, TextField } from "@mui/material";
import { Text } from "~shared/components/Text";
import { useSelect } from "./lib/useSelect";
import { parseTelegramName } from "~/shared/lib/telegramName";

type TelegramProfilesAutocompleteProps = {
  onFormChange: (event: number) => void;
  onBlur: FocusEventHandler<HTMLDivElement>;
  isError: boolean;
  initValue?: number;
};

export const TelegramProfilesAutocomplete: React.FC<TelegramProfilesAutocompleteProps> = ({
  onFormChange,
  onBlur,
  isError
}) => {
  const { value, handleChange, options, handleScroll, isLoading } = useSelect(onFormChange);

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      onBlur={onBlur}
      fullWidth
      getOptionLabel={(option) =>
        (parseTelegramName(option?.name ?? "").name || option.telegram_id) ?? ""
      }
      defaultValue={options[0]}
      renderInput={(params) => (
        <TextField
          {...params}
          name='users'
          error={isError}
          label={<Text>Пользователь</Text>}
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Fragment key={`${option.id}`}>
            <MenuItem {...props} id={`${option.telegram_id}`}>
              {parseTelegramName(option?.name ?? "").name || option.telegram_id}
            </MenuItem>
            {option.id === options[options.length - 1].id && isLoading && (
              <Box className='flex w-full justify-center items-center'>
                <CircularProgress />
              </Box>
            )}
          </Fragment>
        );
      }}
      ListboxProps={{
        onScroll: handleScroll,
        style: { overflowX: "hidden" }
      }}
    />
  );
};
