import { useMutation } from "@tanstack/react-query";
import { useAlertsStore } from "~/shared/stores/alerts";
import { useAuthStore } from "~/shared/stores/auth";
import { fetchPost } from "~shared/lib/fetchPost";

export type VariablesToAll = { message: string };

export const useSendTelegramMessageToAll = () => {
  return useMutation<unknown, unknown, VariablesToAll, unknown>({
    mutationFn: (data) =>
      fetchPost({
        url: `${process.env.REACT_APP_TELEGRAM_API_URL}/api/system_message`,
        headers: { Authorization: `Bearer ${useAuthStore.getState().token}` }
      })(data)
        .then(() => useAlertsStore.getState().addAlert("success", "Успешно отправлено"))
        .catch(() => useAlertsStore.getState().addAlert("error", "Ошибка отправки"))
  });
};

export type VariablesToIds = { message: string; userIds: string[] };

export const useSendTelegramMessageToIds = () => {
  return useMutation<unknown, unknown, VariablesToIds, unknown>({
    mutationFn: (data) =>
      fetchPost({
        url: `${process.env.REACT_APP_TELEGRAM_API_URL}/api/send_message`,
        headers: { Authorization: `Bearer ${useAuthStore.getState().token}` }
      })(data)
        .then(() => useAlertsStore.getState().addAlert("success", "Успешно отправлено"))
        .catch(() => useAlertsStore.getState().addAlert("error", "Ошибка отправки"))
  });
};
