import { Box, IconButton, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import TelegramIcon from "@mui/icons-material/Telegram";
import { useModal } from "~/shared/hooks/useModal";
import { Modal } from "~/shared/components/Modal";
import { Button } from "~/shared/components/Button";
import { Text } from "~/shared/components/Text";
import {
  VariablesToAll,
  VariablesToIds,
  useSendTelegramMessageToAll,
  useSendTelegramMessageToIds
} from "~/api/sendTelegramMessage";
import { TelegramProfilesAutocomplete } from "../TelegramProfilesAutocomplete";

export const HeaderSendTelegramMessage: React.FC = () => {
  const { handleClose, defaultHandleOpen, open } = useModal();

  const { handleSubmit, control, register, reset } = useForm();

  const { mutateAsync: send, isLoading } = useSendTelegramMessageToAll();

  const { mutateAsync: sendToIds, isLoading: isToIdsLoading } = useSendTelegramMessageToIds();

  const onSubmit = handleSubmit((fields) => {
    const input: VariablesToAll = {
      message: fields.message
    };

    if (fields.telegramId) {
      (input as VariablesToIds).userIds = [fields.telegramId];

      sendToIds(input as VariablesToIds);
      return;
    }

    send(input).finally(handleClose);
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [reset, open]);

  return (
    <>
      <IconButton onClick={defaultHandleOpen}>
        <TelegramIcon />
      </IconButton>

      <Modal title='Сообщение всем пользователям телеграм' open={!!open} onClose={handleClose}>
        <Box
          onSubmit={onSubmit}
          component='form'
          className='w-full h-full flex flex-col p-6 gap-6 items-center justify-center'
        >
          <Controller
            control={control}
            name='telegramId'
            render={({ field: { onChange, onBlur } }) => (
              <TelegramProfilesAutocomplete
                onFormChange={onChange}
                onBlur={onBlur}
                isError={false}
              />
            )}
          />

          <Controller
            control={control}
            name='message'
            render={({ field: { value } }) => (
              <TextField
                multiline
                fullWidth
                minRows={4}
                value={value ?? ""}
                variant='outlined'
                label={<Text>Сообщение</Text>}
                {...register("message")}
              />
            )}
          />

          <Button
            disabled={isLoading || isToIdsLoading}
            type='submit'
            fullWidth
            color='primary'
            variant='contained'
          >
            Отправить
          </Button>
        </Box>
      </Modal>
    </>
  );
};
