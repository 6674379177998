export const RootRoute = "/";

export const HomePageRoute = "/";

export const LoginPageRoute = "/login";

export const UsersPageRoute = "/users";

export const UsersPageEdit = `${UsersPageRoute}/edit/:id`;

export const UsersPageCreate = `${UsersPageRoute}/create`;

export const ProfilePageEdit = "/profile/edit/:id";

export const PaymentsRoute = "/payments";

export const BalanceLogsRoute = "/balance-logs";

export const SettingsPageRoute = "/settings";

export const AiRequestsRoute = "/ai-requests";

export const AisRoute = "/ais";

export const AisPageEdit = "/ais/edit/:id";

export const AiProvidersRoute = "/ai-providers";

export const AiProviderPageEdit = "/ai-providers/edit/:id";

export const AiProviderAccountsPageRoute = "/ai-accounts-providers";

export const AiProviderAccountPageEdit = `${AiProviderAccountsPageRoute}/edit/:id`;

export const AiProviderAccountPageCreate = `${AiProviderAccountsPageRoute}/create`;

export const AiProviderUsagesPageRoute = `${AiProviderAccountsPageRoute}/usages/:id`;
