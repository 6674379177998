export const formatTelegramName = ({
  firstName,
  lastName,
  userId,
  userName
}: {
  firstName?: string;
  lastName?: string;
  userName?: string;
  userId?: string;
}) => {
  return `UserId: ${userId}, UserName: ${userName}, Name: ${[firstName, lastName]
    .filter(Boolean)
    .join(" ")}`;
};

export const parseTelegramName = (name: string) => {
  const id =
    name
      .match(/UserId: \d+,/g)
      ?.at(-1)
      ?.split(": ")
      ?.at(-1)
      ?.slice(0, -1) ?? "";

  const userName =
    name
      .match(/UserName: .+?,/g)
      ?.at(-1)
      ?.split(": ")
      ?.at(-1)
      ?.slice(0, -1) ?? "";

  const fullName =
    name
      .match(/Name: [A-Za-zА-Яа-яЁё\s_-\d]+,/g)
      ?.at(-1)
      ?.split(": ")
      ?.at(-1)
      ?.slice(0, -1) ?? "";

  if (!id && !userName && !fullName) {
    return {
      link: "",
      name
    };
  }

  const link = userName ? `https://t.me/${userName}` : `tg://user?id=${id}`;

  const telegramName = fullName || userName || id;

  return {
    link,
    name: telegramName === "undefined" ? id : telegramName,
    id,
    userName,
    fullName
  };
};
