import { create } from "zustand";

export type AuthState = {
  token: string;
  refresh?: string | null;

  auth: (token: string, refresh: string | null) => void;
  unAuth: () => void;
};

const keys = {
  access: "__at_app_admin",
  refresh: "__rt_app_admin"
};

export const useAuthStore = create<AuthState>(
  (set): AuthState => ({
    token: localStorage.getItem(keys.access) || "",
    refresh: localStorage.getItem(keys.refresh) || "",

    auth: (token: string, refresh: string | null) => {
      set(() => ({ token, refresh }));

      localStorage.setItem(keys.access, token);
      localStorage.setItem(keys.refresh, token);
    },

    unAuth: () => {
      set(() => ({ token: "", refresh: null }));

      localStorage.removeItem(keys.access);
      localStorage.removeItem(keys.refresh);
    }
  })
);
