export const fetchPost =
  <T>({ url, headers }: { url: string; headers?: Record<string, string> }) =>
  async (data: T) => {
    try {
      return await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers
        },
        body: JSON.stringify(data)
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };
