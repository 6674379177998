import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import { BaseProtectedLayout } from "~/layouts/BaseProtectedLayout";
import { BaseLayout } from "~/layouts/BaseLayout";
import { GuestRoutes } from "~/modules/GuestRoutes";

import * as Paths from "~shared/routes";

const NoMatch = lazy(() =>
  import("~/pages/NoMatch").then((module) => ({ default: module.NoMatch }))
);
const Login = lazy(() => import("~/pages/Login").then((module) => ({ default: module.Login })));
const Home = lazy(() => import("~/pages/Home").then((module) => ({ default: module.Home })));
const Users = lazy(() => import("~/pages/Users").then((module) => ({ default: module.Users })));
const UsersEdit = lazy(() =>
  import("~/pages/UsersEdit").then((module) => ({ default: module.UsersEdit }))
);
const ProfileEdit = lazy(() =>
  import("~/pages/ProfileEdit").then((module) => ({ default: module.ProfileEdit }))
);
const Payments = lazy(() =>
  import("~/pages/Payments").then((module) => ({ default: module.Payments }))
);
const BalanceLogs = lazy(() =>
  import("~/pages/BalanceLogs").then((module) => ({ default: module.BalanceLogs }))
);
const Settings = lazy(() =>
  import("~/pages/Settings").then((module) => ({ default: module.Settings }))
);
const AiRequests = lazy(() =>
  import("~/pages/AiRequests").then((module) => ({ default: module.AiRequests }))
);
const Ais = lazy(() => import("~/pages/Ais").then((module) => ({ default: module.Ais })));
const AisEdit = lazy(() =>
  import("~/pages/AisEdit").then((module) => ({ default: module.AisEdit }))
);
const AisProviders = lazy(() =>
  import("~/pages/AisProviders").then((module) => ({ default: module.AisProviders }))
);
const AisProvidersEdit = lazy(() =>
  import("~/pages/AisProvidersEdit").then((module) => ({ default: module.AisProvidersEdit }))
);
const AisProviderAccounts = lazy(() =>
  import("~/pages/AisProviderAccounts").then((module) => ({ default: module.AisProviderAccounts }))
);
const AisProviderAccountsEdit = lazy(() =>
  import("~/pages/AisProviderAccountsEdit").then((module) => ({
    default: module.AisProviderAccountsEdit
  }))
);
const AisProviderAccountsUsages = lazy(() =>
  import("~/pages/AisProviderAccountsUsages").then((module) => ({
    default: module.AisProviderAccountsUsages
  }))
);

export const Routing: React.FC = () => {
  return (
    <Routes>
      <Route
        path={Paths.LoginPageRoute}
        element={
          <GuestRoutes>
            <Login />
          </GuestRoutes>
        }
      />

      <Route
        path={Paths.HomePageRoute}
        element={
          <BaseProtectedLayout>
            <Home />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.UsersPageRoute}
        element={
          <BaseProtectedLayout>
            <Users />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.UsersPageCreate}
        element={
          <BaseProtectedLayout>
            <UsersEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.UsersPageEdit}
        element={
          <BaseProtectedLayout>
            <UsersEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.ProfilePageEdit}
        element={
          <BaseProtectedLayout>
            <ProfileEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.PaymentsRoute}
        element={
          <BaseProtectedLayout>
            <Payments />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.BalanceLogsRoute}
        element={
          <BaseProtectedLayout>
            <BalanceLogs />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.SettingsPageRoute}
        element={
          <BaseProtectedLayout>
            <Settings />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AiRequestsRoute}
        element={
          <BaseProtectedLayout>
            <AiRequests />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AisRoute}
        element={
          <BaseProtectedLayout>
            <Ais />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AisPageEdit}
        element={
          <BaseProtectedLayout>
            <AisEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AiProvidersRoute}
        element={
          <BaseProtectedLayout>
            <AisProviders />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AiProviderPageEdit}
        element={
          <BaseProtectedLayout>
            <AisProvidersEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AiProviderAccountsPageRoute}
        element={
          <BaseProtectedLayout>
            <AisProviderAccounts />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AiProviderAccountPageEdit}
        element={
          <BaseProtectedLayout>
            <AisProviderAccountsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AiProviderAccountPageCreate}
        element={
          <BaseProtectedLayout>
            <AisProviderAccountsEdit />
          </BaseProtectedLayout>
        }
      />

      <Route
        path={Paths.AiProviderUsagesPageRoute}
        element={
          <BaseProtectedLayout>
            <AisProviderAccountsUsages />
          </BaseProtectedLayout>
        }
      />

      <Route
        path='*'
        element={
          <BaseLayout>
            <NoMatch />
          </BaseLayout>
        }
      />
    </Routes>
  );
};
