import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
  Upload: any;
};

export type Ai = {
  __typename?: 'Ai';
  blocked?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<AiProvider>;
  slug?: Maybe<Scalars['String']>;
  token_cost?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};


export type AiProviderArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
};

export type AiChatChoices = {
  __typename?: 'AiChatChoices';
  finish_reason?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  message?: Maybe<AiChatMessage>;
};

export type AiChatInput = {
  messages: Array<InputMaybe<AiChatMessageInput>>;
  params?: InputMaybe<AiChatParamsInput>;
};

export type AiChatMessage = {
  __typename?: 'AiChatMessage';
  content: Scalars['String'];
  role: Scalars['String'];
};

export type AiChatMessageInput = {
  content: Scalars['String'];
  role: Scalars['String'];
};

export enum AiChatModelEnum {
  ChatGpt3 = 'ChatGPT3',
  ChatGpt3_16K = 'ChatGPT3_16K',
  ChatGpt4 = 'ChatGPT4',
  ChatGpt4Omni = 'ChatGPT4_Omni',
  ChatGpt4OmniMini = 'ChatGPT4_Omni_Mini',
  ChatGpt4Turbo = 'ChatGPT4_Turbo',
  GigaChat = 'GigaChat',
  GigaChatPlus = 'GigaChat_Plus',
  GigaChatPro = 'GigaChat_Pro',
  YandexGpt = 'YandexGPT',
  YandexGptPro = 'YandexGPT_Pro'
}

export type AiChatParamsInput = {
  frequency_penalty?: InputMaybe<Scalars['Float']>;
  max_tokens?: InputMaybe<Scalars['Int']>;
  n?: InputMaybe<Scalars['Float']>;
  presence_penalty?: InputMaybe<Scalars['Float']>;
  stop?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  temperature?: InputMaybe<Scalars['Float']>;
  top_p?: InputMaybe<Scalars['Float']>;
};

export type AiChatResponse = {
  __typename?: 'AiChatResponse';
  choices?: Maybe<Array<Maybe<AiChatChoices>>>;
  created: Scalars['Int'];
  id: Scalars['String'];
  model: Scalars['String'];
  object: Scalars['String'];
  tokens_cost: Scalars['Float'];
  usage: AiUsage;
};

export type AiCompletionChoices = {
  __typename?: 'AiCompletionChoices';
  finish_reason?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  logprobs?: Maybe<AiLogprobsItem>;
  text: Scalars['String'];
};

export type AiCompletionInput = {
  model?: InputMaybe<AiCompletionModelEnum>;
  params?: InputMaybe<AiCompletionParamsInput>;
  text: Scalars['String'];
};

export enum AiCompletionModelEnum {
  Ada = 'ada',
  Babbage = 'babbage',
  Curie = 'curie',
  Davinci = 'davinci'
}

export type AiCompletionParamsInput = {
  best_of?: InputMaybe<Scalars['Int']>;
  echo?: InputMaybe<Scalars['Boolean']>;
  frequency_penalty?: InputMaybe<Scalars['Float']>;
  logprobs?: InputMaybe<Scalars['Int']>;
  max_tokens?: InputMaybe<Scalars['Int']>;
  n?: InputMaybe<Scalars['Float']>;
  presence_penalty?: InputMaybe<Scalars['Float']>;
  stop?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suffix?: InputMaybe<Scalars['String']>;
  temperature?: InputMaybe<Scalars['Float']>;
  top_p?: InputMaybe<Scalars['Float']>;
};

export type AiCompletionResponse = {
  __typename?: 'AiCompletionResponse';
  choices?: Maybe<Array<Maybe<AiCompletionChoices>>>;
  created: Scalars['Int'];
  id: Scalars['String'];
  model: Scalars['String'];
  object: Scalars['String'];
  tokens_cost: Scalars['Float'];
  usage: AiUsage;
};

export enum AiImageModelEnum {
  Dalle = 'Dalle',
  Midjourney = 'Midjourney',
  StableDiffusion = 'StableDiffusion'
}

export type AiInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  token_cost?: InputMaybe<Scalars['Float']>;
};

export type AiLogprobsItem = {
  __typename?: 'AiLogprobsItem';
  text_offset?: Maybe<Array<Maybe<Scalars['Int']>>>;
  token_logprobs?: Maybe<Array<Maybe<Scalars['Float']>>>;
  tokens?: Maybe<Array<Maybe<Scalars['String']>>>;
  top_logprobs?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

/** A paginated list of Ai items. */
export type AiPaginator = {
  __typename?: 'AiPaginator';
  /** A list of Ai items. */
  data: Array<Ai>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AiProvider = {
  __typename?: 'AiProvider';
  accounts?: Maybe<Array<AiProviderAccount>>;
  ais?: Maybe<Array<Ai>>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type AiProviderAccount = {
  __typename?: 'AiProviderAccount';
  ai_provider_id?: Maybe<Scalars['Int']>;
  api_key?: Maybe<Scalars['String']>;
  blocked?: Maybe<Scalars['Boolean']>;
  blocked_reason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  /** Получение данных о использовании баланса за текущий месяц */
  current_spend?: Maybe<Scalars['Float']>;
  id: Scalars['Int'];
  limit?: Maybe<Scalars['Float']>;
  login?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<AiProvider>;
  requests?: Maybe<Array<AiRequest>>;
  updated_at?: Maybe<Scalars['DateTime']>;
  /** Использование лимитов по месяцам */
  usages?: Maybe<Array<AiProviderAccountUsage>>;
};


export type AiProviderAccountUsagesArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type AiProviderAccountInput = {
  ai_provider_id?: InputMaybe<Scalars['Int']>;
  api_key?: InputMaybe<Scalars['String']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  blocked_reason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Float']>;
  login?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A paginated list of AiProviderAccount items. */
export type AiProviderAccountPaginator = {
  __typename?: 'AiProviderAccountPaginator';
  /** A list of AiProviderAccount items. */
  data: Array<AiProviderAccount>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AiProviderAccountUsage = {
  __typename?: 'AiProviderAccountUsage';
  ai_provider_account_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  limit?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Int']>;
  notified?: Maybe<Scalars['JSON']>;
  spend?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Int']>;
};

export type AiProviderInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** A paginated list of AiProvider items. */
export type AiProviderPaginator = {
  __typename?: 'AiProviderPaginator';
  /** A list of AiProvider items. */
  data: Array<AiProvider>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AiRequest = {
  __typename?: 'AiRequest';
  account?: Maybe<AiProviderAccount>;
  ai?: Maybe<Ai>;
  ai_id?: Maybe<Scalars['Int']>;
  ai_provider_account_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['DateTime']>;
  dialog?: Maybe<Dialogable>;
  dialog_id?: Maybe<Scalars['Int']>;
  dialog_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  input?: Maybe<Scalars['JSON']>;
  output?: Maybe<Scalars['JSON']>;
  telegramBot?: Maybe<TelegramBot>;
  telegram_bot_id?: Maybe<Scalars['Int']>;
  tokens?: Maybe<Scalars['Int']>;
  tokens_cost_rub?: Maybe<Scalars['Float']>;
  tokens_cost_usd?: Maybe<Scalars['Float']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of AiRequest items. */
export type AiRequestPaginator = {
  __typename?: 'AiRequestPaginator';
  /** A list of AiRequest items. */
  data: Array<AiRequest>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type AiSummarizationInput = {
  params?: InputMaybe<AiSummarizationParamsInput>;
  text: Scalars['String'];
};

export type AiSummarizationParamsInput = {
  /** параметр переключения между стратегиями генерации (“beamsearch” или “sampling”). Дефолтное значение beamsearch. */
  genstrategy?: InputMaybe<Scalars['String']>;
  /** экспоненциальный штраф к длине.  Дефолтное значение 2.0 */
  length_penalty?: InputMaybe<Scalars['Float']>;
  /** все ngrams такого размера могут встречаться только один раз. Дефолтное значение 3 */
  no_repeat_ngram_size?: InputMaybe<Scalars['Int']>;
  /** параметр генерации текста num_beams. Дефолтное значение 5 */
  num_beams?: InputMaybe<Scalars['Int']>;
  /** кол-во примеров, из которых выбирается лучший финальный текст. Дефолтное значение 6 */
  num_return_sequences?: InputMaybe<Scalars['Int']>;
  /** используется в качестве штрафа за слова, которые уже были сгенерированы. Дефолтное значение 2.0 */
  repetition_penalty?: InputMaybe<Scalars['Float']>;
  /** Дефолтное значение 0.95 */
  temperature?: InputMaybe<Scalars['Float']>;
  /** Дефолтное значение 50 */
  top_k?: InputMaybe<Scalars['Int']>;
  /** Дефолтное значение 0.9 */
  top_p?: InputMaybe<Scalars['Float']>;
};

export type AiSummarizationResponse = {
  __typename?: 'AiSummarizationResponse';
  comment?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  prediction_best: Scalars['String'];
  predictions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AiUsage = {
  __typename?: 'AiUsage';
  completion_tokens: Scalars['Int'];
  prompt_tokens: Scalars['Int'];
  total_tokens?: Maybe<Scalars['Int']>;
};

export type AudioMessage = {
  __typename?: 'AudioMessage';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  message?: Maybe<Message>;
  message_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
};

export type BalanceLog = {
  __typename?: 'BalanceLog';
  action?: Maybe<BalanceLogActionEnum>;
  amount?: Maybe<Scalars['Float']>;
  balance_before?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  payment?: Maybe<Payment>;
  payment_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']>;
};

export enum BalanceLogActionEnum {
  Add = 'Add',
  Minus = 'Minus'
}

/** A paginated list of BalanceLog items. */
export type BalanceLogPaginator = {
  __typename?: 'BalanceLogPaginator';
  /** A list of BalanceLog items. */
  data: Array<BalanceLog>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type BlendMidjourneyImageTelegramInput = {
  context_id: Scalars['String'];
  dimensions?: InputMaybe<MidjourneyBlendDimensionEnum>;
  images_urls: Array<Scalars['String']>;
  telegram_bot_id?: InputMaybe<Scalars['Int']>;
};

export type ConfirmRegisterInput = {
  code: Scalars['Int'];
  login: Scalars['String'];
  login_type: LoginType;
  name?: InputMaybe<Scalars['String']>;
};

export type ConfirmResetPasswordInput = {
  code: Scalars['Int'];
  login: Scalars['String'];
  login_type: LoginType;
};

export type ConfirmResponse = {
  __typename?: 'ConfirmResponse';
  status: Scalars['String'];
  wait: Scalars['Int'];
};

export type CreateTelegramBotInput = {
  /** На самом дела prompt_id */
  dialog_id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  telegram_token?: InputMaybe<Scalars['String']>;
  vector_collection_id?: InputMaybe<Scalars['Int']>;
};

export type CreateTelegramDialogInput = {
  context_id: Scalars['String'];
  system_message?: InputMaybe<Scalars['String']>;
  telegram_bot_id?: InputMaybe<Scalars['Int']>;
};

export type DescribeMidjourneyImageTelegramInput = {
  context_id: Scalars['String'];
  image_url: Scalars['String'];
  telegram_bot_id?: InputMaybe<Scalars['Int']>;
};

export type Dialog = {
  __typename?: 'Dialog';
  ai_model?: Maybe<DialogAiModelEnum>;
  created_at?: Maybe<Scalars['DateTime']>;
  /**
   * Режим истории диалога:
   *     "Error" - Выводим ошибки при окончании лимита (default)
   *     "Window" - При приближении к лимиту токенов делаем выборку без старых сообщений
   *     "Summarization" - формируем смысл предыдущего диалога (не сделано),
   */
  history_mode?: Maybe<DialogHistoryModeEnum>;
  id: Scalars['Int'];
  /** Диалог является промптом */
  is_prompt?: Maybe<Scalars['Boolean']>;
  messages?: Maybe<Array<Message>>;
  name?: Maybe<Scalars['String']>;
  /**
   * Массив параметров диалога
   * Возможные параметры:
   *     Для типа диалога "Chat":
   *         temperature (float от 0 до 2) - температура
   *         presence_penalty (float от -2 до 2) - штраф за присутствие
   *         frequency_penalty (float от -2 до 2) - штраф за частоту
   *     Для типа диалога "Images"
   *         n (int от 1 до 10) - количество изображений
   */
  params?: Maybe<Scalars['JSON']>;
  project?: Maybe<Project>;
  project_id?: Maybe<Scalars['Int']>;
  prompt?: Maybe<Dialog>;
  /** Id промпта который привязан к чату */
  prompt_id?: Maybe<Scalars['Int']>;
  tokens?: Maybe<Scalars['Int']>;
  tokens_cost_rub?: Maybe<Scalars['Float']>;
  tokens_cost_usd?: Maybe<Scalars['Float']>;
  /**
   * Тип диалога:
   *     "Chat" (default)
   *     "Images"
   */
  type?: Maybe<DialogTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']>;
  vectorCollection?: Maybe<VectorCollection>;
  vector_collection_id?: Maybe<Scalars['Int']>;
};


export type DialogMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export enum DialogAiModelEnum {
  Dalle = 'Dalle',
  Midjourney = 'Midjourney',
  StableDiffusion = 'StableDiffusion'
}

export type DialogCreateInput = {
  ai_model?: InputMaybe<DialogAiModelEnum>;
  history_mode?: InputMaybe<DialogHistoryModeEnum>;
  is_prompt?: InputMaybe<Scalars['Boolean']>;
  payload?: InputMaybe<DialogPayloadInput>;
  project_id?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<DialogTypeEnum>;
};

export enum DialogHistoryModeEnum {
  Error = 'Error',
  Summarization = 'Summarization',
  Window = 'Window'
}

/** A paginated list of Dialog items. */
export type DialogPaginator = {
  __typename?: 'DialogPaginator';
  /** A list of Dialog items. */
  data: Array<Dialog>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type DialogPayloadInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  params?: InputMaybe<Scalars['JSON']>;
  prompt_id?: InputMaybe<Scalars['Int']>;
  vector_collection_id?: InputMaybe<Scalars['Int']>;
};

export enum DialogTypeEnum {
  Chat = 'Chat',
  Images = 'Images',
  Voice = 'Voice'
}

export type Dialogable = Dialog | TelegramDialog;

export type FaceSwapFromImagesTelegramInput = {
  context_id: Scalars['String'];
  images_urls: Array<Scalars['String']>;
};

export type FilterByClause = {
  column: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
};

export type FromAudioUrlTelegramInput = {
  ai_type?: InputMaybe<Scalars['String']>;
  context_id: Scalars['String'];
  telegram_bot_id?: InputMaybe<Scalars['Int']>;
  url: Scalars['String'];
};

export type FromTextTelegramPayloadInput = {
  ai_type?: InputMaybe<Scalars['String']>;
  context_id: Scalars['String'];
  telegram_bot_id?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  voice?: InputMaybe<Scalars['String']>;
};

export type GalleryImage = {
  __typename?: 'GalleryImage';
  alt?: Maybe<Scalars['String']>;
  alt_en?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  sort?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
};

export type GenerateMidjourneyImageTelegramInput = {
  action: MidjourneyActionEnum;
  context_id: Scalars['String'];
  image_index?: InputMaybe<Scalars['Int']>;
  message_id: Scalars['Int'];
  telegram_bot_id?: InputMaybe<Scalars['Int']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['Int'];
  url?: Maybe<Scalars['String']>;
};

export type ImageThumbs = {
  __typename?: 'ImageThumbs';
  height?: Maybe<Scalars['Int']>;
  method?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type LoginInput = {
  login: Scalars['String'];
  login_type: LoginType;
  password: Scalars['String'];
};

export enum LoginType {
  Email = 'email'
}

export type MakeImageVariantsTelegramInput = {
  context_id: Scalars['String'];
  gallery_image_id: Scalars['Int'];
  telegram_bot_id?: InputMaybe<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  ai_model?: Maybe<MessageAiModelEnum>;
  ai_request_id?: Maybe<Scalars['Int']>;
  audio_message?: Maybe<AudioMessage>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  dialog?: Maybe<Dialogable>;
  dialog_id?: Maybe<Scalars['Int']>;
  dialog_type?: Maybe<Scalars['String']>;
  gallery?: Maybe<Array<Maybe<GalleryImage>>>;
  galleryThumbs?: Maybe<Array<Maybe<ImageThumbs>>>;
  id: Scalars['Int'];
  role?: Maybe<MessageRoleEnum>;
  tokens_cost_rub?: Maybe<Scalars['Float']>;
  tokens_cost_usd?: Maybe<Scalars['Float']>;
  type?: Maybe<MessageTypeEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export enum MessageAiModelEnum {
  Dalle = 'Dalle',
  Midjourney = 'Midjourney',
  StableDiffusion = 'StableDiffusion'
}

export type MessageInput = {
  content?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<MessageRoleEnum>;
};

/** A paginated list of Message items. */
export type MessagePaginator = {
  __typename?: 'MessagePaginator';
  /** A list of Message items. */
  data: Array<Message>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum MessageRoleEnum {
  Assistant = 'Assistant',
  Info = 'Info',
  System = 'System',
  User = 'User'
}

export enum MessageTypeEnum {
  Chat = 'Chat',
  DescribeVariants = 'Describe_variants',
  Images = 'Images',
  ImagesVariants = 'Images_variants',
  Voice = 'Voice'
}

export enum MidjourneyActionEnum {
  DescribeVariant = 'Describe_variant',
  Regen = 'Regen',
  RegenDescribe = 'Regen_describe',
  SelectVariant = 'Select_variant',
  UpscaleVariant = 'Upscale_variant'
}

export enum MidjourneyBlendDimensionEnum {
  Landscape = 'Landscape',
  Portrait = 'Portrait',
  Square = 'Square'
}

export type Mutation = {
  __typename?: 'Mutation';
  aiChat?: Maybe<AiChatResponse>;
  aiCompletion?: Maybe<AiCompletionResponse>;
  aiSummarization?: Maybe<AiSummarizationResponse>;
  /** Действие 'Смешай' Midjourney */
  blendMidjourneyImageTelegram: Message;
  cancelPayment: Payment;
  cancelSubscription: Scalars['Boolean'];
  /** Изменить изображение по промпту (Stable diffusion only) */
  changeImageByPrompt?: Maybe<Array<Message>>;
  changeUserApiKey?: Maybe<User>;
  changeUserBalance?: Maybe<User>;
  /** Отчистить связь с Prompt */
  cleanRelationPromptFromTelegramBot?: Maybe<TelegramBot>;
  /** Отчистить связь с VectorCollection */
  cleanRelationVectorCollectionFromTelegramBot?: Maybe<TelegramBot>;
  confirmRegister: TokensResponse;
  confirmResetPassword: TokensResponse;
  copyPrompt: Dialog;
  createAdditionalRequestsPayment: Payment;
  createDialog: Dialog;
  createPayment?: Maybe<Payment>;
  createPaymentForRequests?: Maybe<Payment>;
  createPaymentForUser?: Maybe<Payment>;
  createProject?: Maybe<Project>;
  createSubscription: Payment;
  /** Создать бота */
  createTelegramBot?: Maybe<TelegramBot>;
  /** Создать диалог с новым системным сообщением */
  createTelegramDialog: TelegramDialog;
  createTrialSubscription: Payment;
  createVectorCollection?: Maybe<VectorCollection>;
  createVectorDocuments?: Maybe<Array<Maybe<VectorDocument>>>;
  createYearSubscription: Payment;
  deleteAiProviderAccount: AiProviderAccount;
  deleteDialog?: Maybe<Dialog>;
  deleteMessage?: Maybe<Message>;
  deleteProject?: Maybe<Project>;
  deleteSetting?: Maybe<Setting>;
  /** Удалить бота */
  deleteTelegramBot?: Maybe<TelegramBot>;
  /** Нельзя удалить суперпользователя, ID=1 */
  deleteUser?: Maybe<User>;
  deleteVectorCollections?: Maybe<Array<VectorCollection>>;
  deleteVectorDocuments?: Maybe<Array<Maybe<VectorDocument>>>;
  /** Действие 'Опиши' Midjourney */
  describeMidjourneyImageTelegram: Message;
  /** Остановить бота */
  disableTelegramBot?: Maybe<TelegramBot>;
  /** Face Swap с использованием изображений */
  faceSwapFromImagesTelegram: Message;
  generateMidjourneyImage: Message;
  /** Действия с изображениями Midjourney */
  generateMidjourneyImageTelegram: Message;
  generateVectorCollection?: Maybe<VectorCollection>;
  /** Ответить на вопрос */
  getAnswerFromTextTelegram?: Maybe<Message>;
  /** Ответить на вопрос с изображением */
  getAnswerFromTextWithImageTelegram?: Maybe<Message>;
  /** Озвучить текст */
  getAudioFromTextTelegram: Message;
  getImageFromText?: Maybe<Array<Message>>;
  /** Сделать картинку по тексту */
  getImageFromTextTelegram: Message;
  /** Получить текст из голосового сообщения */
  getTextFromAudioUrlTelegram: Message;
  login: TokensResponse;
  logout: User;
  /** Запросить вариации изображения */
  makeImageVariants: Message;
  /** Сделать варианты изображения */
  makeImageVariantsTelegram: Message;
  register: ConfirmResponse;
  /** Telegram auth */
  registerOrLoginTelegramUser: TokensResponse;
  removeBackgroundFromImageUrlTelegram: Message;
  removeDialogPromptRelation?: Maybe<Dialog>;
  removeDialogVectorCollectionRelation?: Maybe<Dialog>;
  /** Повторить последнее сообщение (в случае ошибки при запросе боту) */
  replyLastMessage: Message;
  resetPassword: ConfirmResponse;
  /** Перезапусить бота (в случае ошибки?) */
  restartTelegramBot?: Maybe<TelegramBot>;
  /**
   * Отправить сообщение
   * Описание возможных типов:
   *     - Chat - отправляем обычное сообщение ai
   *     - Images - отправляем сообщение на генерацию изображения.
   *         Если 'upload' не пустой, то на его основе генерируем изменненое изображение по описанию из 'message'
   *     - Voice - отправляем сообщение на генерацию голосового сообщения
   *         Если 'audio_message' не пустой, то на его основе генерируем аудио
   */
  sendMessage?: Maybe<Array<Message>>;
  setUserSubscription?: Maybe<User>;
  /** Запустить бота */
  startTelegramBot?: Maybe<TelegramBot>;
  subscribeAutoRefill?: Maybe<Payment>;
  unsubscribeAutoRefill?: Maybe<User>;
  updateAi: Ai;
  updateAiProvider: AiProvider;
  updateDialog: Dialog;
  updateMessage: Message;
  updateProject?: Maybe<Project>;
  /** Изменить поля бота */
  updateTelegramBot?: Maybe<TelegramBot>;
  updateVectorCollection?: Maybe<VectorCollection>;
  upsertAiProviderAccount: AiProviderAccount;
  upsertSelf?: Maybe<User>;
  upsertSetting?: Maybe<Setting>;
  upsertSystemMessage: Message;
  upsertUser?: Maybe<User>;
};


export type MutationAiChatArgs = {
  input: AiChatInput;
};


export type MutationAiCompletionArgs = {
  input: AiCompletionInput;
};


export type MutationAiSummarizationArgs = {
  input: AiSummarizationInput;
};


export type MutationBlendMidjourneyImageTelegramArgs = {
  input: BlendMidjourneyImageTelegramInput;
};


export type MutationCancelPaymentArgs = {
  payment_id: Scalars['Int'];
};


export type MutationChangeImageByPromptArgs = {
  dialog_id: Scalars['Int'];
  image: Scalars['Upload'];
  prompt: Scalars['String'];
};


export type MutationChangeUserApiKeyArgs = {
  id: Scalars['Int'];
};


export type MutationChangeUserBalanceArgs = {
  amount?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
};


export type MutationCleanRelationPromptFromTelegramBotArgs = {
  id: Scalars['Int'];
};


export type MutationCleanRelationVectorCollectionFromTelegramBotArgs = {
  id: Scalars['Int'];
};


export type MutationConfirmRegisterArgs = {
  input: ConfirmRegisterInput;
};


export type MutationConfirmResetPasswordArgs = {
  input: ConfirmResetPasswordInput;
};


export type MutationCopyPromptArgs = {
  id: Scalars['Int'];
};


export type MutationCreateAdditionalRequestsPaymentArgs = {
  amount: Scalars['Int'];
  requestsCount: Scalars['Int'];
};


export type MutationCreateDialogArgs = {
  input: DialogCreateInput;
};


export type MutationCreatePaymentArgs = {
  input: PaymentPayloadInput;
};


export type MutationCreatePaymentForRequestsArgs = {
  input: CreatePaymentForRequestsInput;
};


export type MutationCreatePaymentForUserArgs = {
  input: PaymentAdminInput;
};


export type MutationCreateProjectArgs = {
  input: ProjectPayloadInput;
};


export type MutationCreateSubscriptionArgs = {
  amount: Scalars['Int'];
  requestsCount: Scalars['Int'];
};


export type MutationCreateTelegramBotArgs = {
  input: CreateTelegramBotInput;
};


export type MutationCreateTelegramDialogArgs = {
  input: CreateTelegramDialogInput;
};


export type MutationCreateTrialSubscriptionArgs = {
  requestsCount: Scalars['Int'];
};


export type MutationCreateVectorCollectionArgs = {
  name: Scalars['String'];
};


export type MutationCreateVectorDocumentsArgs = {
  uploaded_files: Array<Scalars['Upload']>;
  vector_collection_id: Scalars['Int'];
};


export type MutationCreateYearSubscriptionArgs = {
  amount: Scalars['Int'];
  requestsCount: Scalars['Int'];
};


export type MutationDeleteAiProviderAccountArgs = {
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationDeleteDialogArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMessageArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteProjectArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteSettingArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteTelegramBotArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteVectorCollectionsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDeleteVectorDocumentsArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationDescribeMidjourneyImageTelegramArgs = {
  input: DescribeMidjourneyImageTelegramInput;
};


export type MutationDisableTelegramBotArgs = {
  id: Scalars['Int'];
};


export type MutationFaceSwapFromImagesTelegramArgs = {
  input: FaceSwapFromImagesTelegramInput;
};


export type MutationGenerateMidjourneyImageArgs = {
  action: MidjourneyActionEnum;
  dialog_id: Scalars['Int'];
  image_index?: InputMaybe<Scalars['Int']>;
  message_id: Scalars['Int'];
};


export type MutationGenerateMidjourneyImageTelegramArgs = {
  input: GenerateMidjourneyImageTelegramInput;
};


export type MutationGenerateVectorCollectionArgs = {
  id: Scalars['Int'];
};


export type MutationGetAnswerFromTextTelegramArgs = {
  input: GetAnswerFromTextTelegramInput;
};


export type MutationGetAnswerFromTextWithImageTelegramArgs = {
  input: GetAnswerFromTextWithImageTelegramInput;
};


export type MutationGetAudioFromTextTelegramArgs = {
  input: FromTextTelegramPayloadInput;
};


export type MutationGetImageFromTextArgs = {
  ai_images_model?: InputMaybe<AiImageModelEnum>;
  dialog_id: Scalars['Int'];
  text: Scalars['String'];
};


export type MutationGetImageFromTextTelegramArgs = {
  input: GetImageFromTextTelegramInput;
};


export type MutationGetTextFromAudioUrlTelegramArgs = {
  input?: InputMaybe<FromAudioUrlTelegramInput>;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationMakeImageVariantsArgs = {
  dialog_id: Scalars['Int'];
  gallery_image_id: Scalars['Int'];
};


export type MutationMakeImageVariantsTelegramArgs = {
  input?: InputMaybe<MakeImageVariantsTelegramInput>;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRegisterOrLoginTelegramUserArgs = {
  input: RegisterOrLoginTelegramUserInput;
};


export type MutationRemoveBackgroundFromImageUrlTelegramArgs = {
  input: RemoveBackgroundFromImageUrlTelegramInput;
};


export type MutationRemoveDialogPromptRelationArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveDialogVectorCollectionRelationArgs = {
  id: Scalars['Int'];
};


export type MutationReplyLastMessageArgs = {
  dialog_id: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRestartTelegramBotArgs = {
  id: Scalars['Int'];
};


export type MutationSendMessageArgs = {
  audio_message?: InputMaybe<Scalars['Upload']>;
  dialog_id: Scalars['Int'];
  message?: InputMaybe<Scalars['String']>;
  type: DialogTypeEnum;
  upload?: InputMaybe<Scalars['Upload']>;
};


export type MutationSetUserSubscriptionArgs = {
  subscriptionType: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationStartTelegramBotArgs = {
  id: Scalars['Int'];
};


export type MutationSubscribeAutoRefillArgs = {
  input: SubscribeAutoRefillInput;
};


export type MutationUpdateAiArgs = {
  input: AiInput;
};


export type MutationUpdateAiProviderArgs = {
  input: AiProviderInput;
};


export type MutationUpdateDialogArgs = {
  input: DialogPayloadInput;
};


export type MutationUpdateMessageArgs = {
  input?: InputMaybe<MessageInput>;
};


export type MutationUpdateProjectArgs = {
  input: ProjectUpdateInput;
};


export type MutationUpdateTelegramBotArgs = {
  input: UpdateTelegramBotInput;
};


export type MutationUpdateVectorCollectionArgs = {
  input: UpdateVectorCollectionInput;
};


export type MutationUpsertAiProviderAccountArgs = {
  input?: InputMaybe<AiProviderAccountInput>;
};


export type MutationUpsertSelfArgs = {
  input: UserInput;
};


export type MutationUpsertSettingArgs = {
  input: SettingInput;
};


export type MutationUpsertSystemMessageArgs = {
  content: Scalars['String'];
  dialog_id: Scalars['Int'];
};


export type MutationUpsertUserArgs = {
  input: UserInput;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Amount of items. */
  Count = 'COUNT',
  /** Maximum. */
  Max = 'MAX',
  /** Minimum. */
  Min = 'MIN',
  /** Sum. */
  Sum = 'SUM'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Scalars['Float']>;
  created_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** Количество покупаемых запросов (только telegram) */
  requests_count?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatusEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

export type PaymentAdminInput = {
  payload: PaymentPayloadInput;
  user_id: Scalars['Int'];
};

export type PaymentDirectDebit = {
  __typename?: 'PaymentDirectDebit';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** A paginated list of Payment items. */
export type PaymentPaginator = {
  __typename?: 'PaymentPaginator';
  /** A list of Payment items. */
  data: Array<Payment>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type PaymentPayloadInput = {
  amount: Scalars['Float'];
  callback_fail_url?: InputMaybe<Scalars['String']>;
  callback_success_url?: InputMaybe<Scalars['String']>;
};

export enum PaymentStatusEnum {
  Authorized = 'Authorized',
  Confirmed = 'Confirmed',
  Fail = 'Fail',
  New = 'New',
  PartialRefunded = 'Partial_refunded',
  PartialReversed = 'Partial_reversed',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
  Reversed = 'Reversed'
}

export type Project = {
  __typename?: 'Project';
  created_at?: Maybe<Scalars['DateTime']>;
  dialogs?: Maybe<Array<Dialog>>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of Project items. */
export type ProjectPaginator = {
  __typename?: 'ProjectPaginator';
  /** A list of Project items. */
  data: Array<Project>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type ProjectPayloadInput = {
  name: Scalars['String'];
};

export type ProjectUpdateInput = {
  id: Scalars['Int'];
  payload: ProjectPayloadInput;
};

export type Query = {
  __typename?: 'Query';
  aiProviderAccounts: AiProviderAccountPaginator;
  aiProviders: AiProviderPaginator;
  aiRequests: AiRequestPaginator;
  ais: AiPaginator;
  balanceLogById?: Maybe<BalanceLog>;
  balanceLogs: BalanceLogPaginator;
  dialogById?: Maybe<Dialog>;
  galleryImageById: GalleryImage;
  getPromptTemplates: DialogPaginator;
  getTelegramDialogMessages: MessagePaginator;
  getVectorDocumentsByVectorCollectionId: VectorDocumentPaginator;
  me: User;
  paymentById?: Maybe<Payment>;
  paymentRequests: Array<PaymentDirectDebit>;
  payments: PaymentPaginator;
  settingById?: Maybe<Setting>;
  settingByName?: Maybe<Setting>;
  settings: Array<Setting>;
  telegramBots: TelegramBotPaginator;
  telegramDialogById?: Maybe<TelegramDialog>;
  userBalanceLogs: BalanceLogPaginator;
  userByEmail?: Maybe<User>;
  userById?: Maybe<User>;
  userDialogs: DialogPaginator;
  userPaymentRequests: Array<PaymentDirectDebit>;
  userProjects: ProjectPaginator;
  userTelegramBots: TelegramBotPaginator;
  userTelegramDialogs: DialogPaginator;
  userVectorCollections: VectorCollectionPaginator;
  users: UserPaginator;
};


export type QueryAiProviderAccountsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAiProvidersArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAiRequestsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryAisArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryBalanceLogByIdArgs = {
  id: Scalars['Int'];
};


export type QueryBalanceLogsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryDialogByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGalleryImageByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetPromptTemplatesArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTelegramDialogMessagesArgs = {
  context_id: Scalars['String'];
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<OrderByClause>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetVectorDocumentsByVectorCollectionIdArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
  vector_collection_id: Scalars['Int'];
};


export type QueryPaymentByIdArgs = {
  id: Scalars['Int'];
};


export type QueryPaymentRequestsArgs = {
  endDate: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['String'];
};


export type QueryPaymentsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QuerySettingByIdArgs = {
  id: Scalars['Int'];
};


export type QuerySettingByNameArgs = {
  name: Scalars['String'];
};


export type QuerySettingsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  orderBy?: InputMaybe<Array<OrderByClause>>;
};


export type QueryTelegramBotsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryTelegramDialogByIdArgs = {
  id: Scalars['Int'];
};


export type QueryUserBalanceLogsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserByIdArgs = {
  id: Scalars['Int'];
};


export type QueryUserDialogsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserPaymentRequestsArgs = {
  endDate: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['String'];
};


export type QueryUserProjectsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserTelegramBotsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserTelegramDialogsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUserVectorCollectionsArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<Array<FilterByClause>>;
  first?: Scalars['Int'];
  orderBy?: InputMaybe<Array<OrderByClause>>;
  page?: InputMaybe<Scalars['Int']>;
};

export type RegisterInput = {
  from_admin?: InputMaybe<Scalars['Boolean']>;
  login: Scalars['String'];
  login_type: LoginType;
  name?: InputMaybe<Scalars['String']>;
};

export type RegisterOrLoginTelegramUserInput = {
  name?: InputMaybe<Scalars['String']>;
  telegram_id: Scalars['String'];
};

export type RemoveBackgroundFromImageUrlTelegramInput = {
  context_id: Scalars['String'];
  image_url: Scalars['String'];
};

export type ResetPasswordInput = {
  from_admin?: InputMaybe<Scalars['Boolean']>;
  login: Scalars['String'];
  login_type: LoginType;
};

export enum RoleType {
  Admin = 'ADMIN',
  User = 'USER'
}

export type Setting = {
  __typename?: 'Setting';
  id: Scalars['Int'];
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type SettingInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type TelegramBot = {
  __typename?: 'TelegramBot';
  created_at?: Maybe<Scalars['DateTime']>;
  /** На самом дела prompt_id */
  dialog_id?: Maybe<Scalars['Int']>;
  error_message?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  options?: Maybe<Scalars['JSON']>;
  prompt?: Maybe<Dialog>;
  status?: Maybe<TelegramBotStatusEnum>;
  telegram_token?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']>;
  vectorCollection?: Maybe<VectorCollection>;
  vector_collection_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of TelegramBot items. */
export type TelegramBotPaginator = {
  __typename?: 'TelegramBotPaginator';
  /** A list of TelegramBot items. */
  data: Array<TelegramBot>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum TelegramBotStatusEnum {
  Disabled = 'Disabled',
  Disabling = 'Disabling',
  Error = 'Error',
  Restarting = 'Restarting',
  Starting = 'Starting',
  Working = 'Working'
}

export type TelegramDialog = {
  __typename?: 'TelegramDialog';
  context_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  /** Промпт общения бота (берется только если диалог не прикреплен к боту) */
  system_message?: Maybe<Scalars['String']>;
  telegramBot?: Maybe<TelegramBot>;
  telegram_bot_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  user_id?: Maybe<Scalars['Int']>;
};

export type TokensResponse = {
  __typename?: 'TokensResponse';
  access_token: Scalars['String'];
  refresh_token?: Maybe<Scalars['String']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpdateTelegramBotInput = {
  /** На самом дела prompt_id */
  dialog_id?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Scalars['JSON']>;
  telegram_token?: InputMaybe<Scalars['String']>;
  vector_collection_id?: InputMaybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  api_key?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  blocked: Scalars['Boolean'];
  blocked_reason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  last_charge_date?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_verified_at?: Maybe<Scalars['DateTime']>;
  /** ID подписки для автопополнения, есть если подключена подписка */
  rebill_id?: Maybe<Scalars['String']>;
  /** Количество запросов доступные пользователю (только telegram) */
  requests_count?: Maybe<Scalars['Int']>;
  role: RoleType;
  subscription_type?: Maybe<Scalars['String']>;
  subscriptions_requests_count?: Maybe<Scalars['Int']>;
  telegram_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
};

export type UserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  blocked_reason?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<RoleType>;
  telegram_id?: InputMaybe<Scalars['String']>;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** A list of User items. */
  data: Array<User>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type VectorCollection = {
  __typename?: 'VectorCollection';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<VectorCollectionStatusEnum>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_id?: Maybe<Scalars['Int']>;
  vectorDocuments?: Maybe<Array<VectorDocument>>;
};

/** A paginated list of VectorCollection items. */
export type VectorCollectionPaginator = {
  __typename?: 'VectorCollectionPaginator';
  /** A list of VectorCollection items. */
  data: Array<VectorCollection>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export enum VectorCollectionStatusEnum {
  Changed = 'Changed',
  Error = 'Error',
  New = 'New',
  Processing = 'Processing',
  Ready = 'Ready'
}

export type VectorDocument = {
  __typename?: 'VectorDocument';
  created_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['DateTime']>;
  vector_collection_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of VectorDocument items. */
export type VectorDocumentPaginator = {
  __typename?: 'VectorDocumentPaginator';
  /** A list of VectorDocument items. */
  data: Array<VectorDocument>;
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
};

export type CreatePaymentForRequestsInput = {
  callback_fail_url?: InputMaybe<Scalars['String']>;
  callback_success_url?: InputMaybe<Scalars['String']>;
  requests_count: Scalars['Int'];
};

export type GetAnswerFromTextTelegramInput = {
  ai_chat_model?: InputMaybe<AiChatModelEnum>;
  payload?: InputMaybe<FromTextTelegramPayloadInput>;
};

export type GetAnswerFromTextWithImageTelegramInput = {
  ai_chat_model?: InputMaybe<AiChatModelEnum>;
  image_url: Scalars['String'];
  payload?: InputMaybe<FromTextTelegramPayloadInput>;
};

export type GetImageFromTextTelegramInput = {
  ai_images_model?: InputMaybe<AiImageModelEnum>;
  payload?: InputMaybe<FromTextTelegramPayloadInput>;
};

export type SubscribeAutoRefillInput = {
  callback_fail_url?: InputMaybe<Scalars['String']>;
  callback_success_url?: InputMaybe<Scalars['String']>;
};

export type UpdateVectorCollectionInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type AllAiProviderAccountFieldsFragment = { __typename?: 'AiProviderAccount', id: number, name?: string | null, login?: string | null, limit?: number | null, blocked?: boolean | null, blocked_reason?: string | null, ai_provider_id?: number | null, current_spend?: number | null, created_at?: any | null, provider?: { __typename?: 'AiProvider', id?: number | null, name?: string | null } | null };

export type AiProviderAccountsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type AiProviderAccountsQuery = { __typename?: 'Query', aiProviderAccounts: { __typename?: 'AiProviderAccountPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiProviderAccount', id: number, name?: string | null, login?: string | null, limit?: number | null, blocked?: boolean | null, blocked_reason?: string | null, ai_provider_id?: number | null, current_spend?: number | null, created_at?: any | null, provider?: { __typename?: 'AiProvider', id?: number | null, name?: string | null } | null }> } };

export type AiProviderAccountByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AiProviderAccountByIdQuery = { __typename?: 'Query', aiProviderAccountById: { __typename?: 'AiProviderAccountPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiProviderAccount', api_key?: string | null, id: number, name?: string | null, login?: string | null, limit?: number | null, blocked?: boolean | null, blocked_reason?: string | null, ai_provider_id?: number | null, current_spend?: number | null, created_at?: any | null, provider?: { __typename?: 'AiProvider', id?: number | null, name?: string | null } | null }> } };

export type AiProviderAccountUsagesQueryVariables = Exact<{
  id: Scalars['String'];
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type AiProviderAccountUsagesQuery = { __typename?: 'Query', aiProviderAccountUsages: { __typename?: 'AiProviderAccountPaginator', data: Array<{ __typename?: 'AiProviderAccount', usages?: Array<{ __typename?: 'AiProviderAccountUsage', id: number, month?: number | null, year?: number | null, limit?: number | null, spend?: number | null, notified?: any | null }> | null }> } };

export type CreateAiProviderAccountMutationVariables = Exact<{
  input: AiProviderAccountInput;
}>;


export type CreateAiProviderAccountMutation = { __typename?: 'Mutation', createAiProviderAccount: { __typename?: 'AiProviderAccount', id: number } };

export type UpdateAiProviderAccountMutationVariables = Exact<{
  input: AiProviderAccountInput;
}>;


export type UpdateAiProviderAccountMutation = { __typename?: 'Mutation', upsertAiProviderAccount: { __typename?: 'AiProviderAccount', id: number } };

export type DeleteAiProviderAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteAiProviderAccountMutation = { __typename?: 'Mutation', deleteAiProviderAccount: { __typename?: 'AiProviderAccount', id: number } };

export type AllAiProviderFieldsFragment = { __typename?: 'AiProvider', id?: number | null, name?: string | null, description?: string | null, created_at?: any | null };

export type AiProvidersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type AiProvidersQuery = { __typename?: 'Query', aiProviders: { __typename?: 'AiProviderPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiProvider', id?: number | null, name?: string | null, description?: string | null, created_at?: any | null }> } };

export type AiProvidersSelectQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type AiProvidersSelectQuery = { __typename?: 'Query', aiProvidersSelect: { __typename?: 'AiProviderPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiProvider', id?: number | null, name?: string | null }> } };

export type AiProviderByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AiProviderByIdQuery = { __typename?: 'Query', aiProviderById: { __typename?: 'AiProviderPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiProvider', id?: number | null, name?: string | null, description?: string | null, created_at?: any | null, accounts?: Array<{ __typename?: 'AiProviderAccount', id: number, name?: string | null }> | null, ais?: Array<{ __typename?: 'Ai', id?: number | null, name?: string | null }> | null }> } };

export type UpdateAiProviderMutationVariables = Exact<{
  input: AiProviderInput;
}>;


export type UpdateAiProviderMutation = { __typename?: 'Mutation', updateAiProvider: { __typename?: 'AiProvider', id?: number | null, name?: string | null, description?: string | null, created_at?: any | null } };

export type AllAiRequestsFieldsFragment = { __typename?: 'AiRequest', id?: number | null, user_id?: number | null, ai_provider_account_id?: number | null, tokens_cost_rub?: number | null, user?: { __typename?: 'User', id: number, email?: string | null, name?: string | null } | null, ai?: { __typename?: 'Ai', id?: number | null, name?: string | null, model?: string | null, type?: string | null, description?: string | null, content?: string | null, blocked?: boolean | null } | null };

export type AiRequestsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type AiRequestsQuery = { __typename?: 'Query', aiRequests: { __typename?: 'AiRequestPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiRequest', id?: number | null, user_id?: number | null, ai_provider_account_id?: number | null, tokens_cost_rub?: number | null, user?: { __typename?: 'User', id: number, email?: string | null, name?: string | null } | null, ai?: { __typename?: 'Ai', id?: number | null, name?: string | null, model?: string | null, type?: string | null, description?: string | null, content?: string | null, blocked?: boolean | null } | null }> } };

export type AiRequestByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AiRequestByIdQuery = { __typename?: 'Query', aiRequestById: { __typename?: 'AiRequestPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiRequest', id?: number | null, user_id?: number | null, ai_provider_account_id?: number | null, tokens_cost_rub?: number | null, user?: { __typename?: 'User', id: number, email?: string | null, name?: string | null } | null, ai?: { __typename?: 'Ai', id?: number | null, name?: string | null, model?: string | null, type?: string | null, description?: string | null, content?: string | null, blocked?: boolean | null } | null }> } };

export type AiRequestPayloadByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AiRequestPayloadByIdQuery = { __typename?: 'Query', aiRequestPayloadById: { __typename?: 'AiRequestPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'AiRequest', input?: any | null, output?: any | null }> } };

export type AllAiFieldsFragment = { __typename?: 'Ai', id?: number | null, name?: string | null, model?: string | null, type?: string | null, token_cost?: number | null, description?: string | null, content?: string | null, blocked?: boolean | null, created_at?: any | null, provider?: { __typename?: 'AiProvider', id?: number | null, name?: string | null } | null };

export type AisQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type AisQuery = { __typename?: 'Query', ais: { __typename?: 'AiPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'Ai', id?: number | null, name?: string | null, model?: string | null, type?: string | null, token_cost?: number | null, description?: string | null, content?: string | null, blocked?: boolean | null, created_at?: any | null, provider?: { __typename?: 'AiProvider', id?: number | null, name?: string | null } | null }> } };

export type UpdateAiMutationVariables = Exact<{
  input: AiInput;
}>;


export type UpdateAiMutation = { __typename?: 'Mutation', updateAi: { __typename?: 'Ai', id?: number | null } };

export type TokenResponseFragment = { __typename?: 'TokensResponse', access_token: string, refresh_token?: string | null };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'TokensResponse', access_token: string, refresh_token?: string | null } };

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'ConfirmResponse', status: string, wait: number } };

export type ConfirmRegisterMutationVariables = Exact<{
  input: ConfirmRegisterInput;
}>;


export type ConfirmRegisterMutation = { __typename?: 'Mutation', confirmRegister: { __typename?: 'TokensResponse', access_token: string, refresh_token?: string | null } };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ConfirmResponse', status: string, wait: number } };

export type ConfirmResetPasswordMutationVariables = Exact<{
  input: ConfirmResetPasswordInput;
}>;


export type ConfirmResetPasswordMutation = { __typename?: 'Mutation', confirmResetPassword: { __typename?: 'TokensResponse', access_token: string, refresh_token?: string | null } };

export type BalanceLogsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type BalanceLogsQuery = { __typename?: 'Query', balanceLogs: { __typename?: 'BalanceLogPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number }, data: Array<{ __typename?: 'BalanceLog', id: number, amount?: number | null, balance_before?: number | null, action?: BalanceLogActionEnum | null, description?: string | null, user_id?: number | null, payment_id?: number | null, updated_at?: any | null, created_at?: any | null, user?: { __typename?: 'User', email?: string | null, name?: string | null } | null }> } };

export type ChangeUserBalanceMutationVariables = Exact<{
  id: Scalars['Int'];
  amount?: InputMaybe<Scalars['Float']>;
}>;


export type ChangeUserBalanceMutation = { __typename?: 'Mutation', changeUserBalance?: { __typename?: 'User', balance?: number | null } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, name?: string | null, role: RoleType } };

export type PaymentsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type PaymentsQuery = { __typename?: 'Query', payments: { __typename?: 'PaymentPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number }, data: Array<{ __typename?: 'Payment', amount?: number | null, created_at?: any | null, description?: string | null, id: number, status?: PaymentStatusEnum | null, url?: string | null, updated_at?: any | null, user_id?: number | null, user?: { __typename?: 'User', email?: string | null, name?: string | null } | null }> } };

export type CreatePaymentForUserMutationVariables = Exact<{
  input: PaymentAdminInput;
}>;


export type CreatePaymentForUserMutation = { __typename?: 'Mutation', createPaymentForUser?: { __typename?: 'Payment', url?: string | null } | null };

export type CreatePaymentMutationVariables = Exact<{
  input: PaymentPayloadInput;
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', createPayment?: { __typename?: 'Payment', url?: string | null } | null };

export type CancelPaymentMutationVariables = Exact<{
  payment_id: Scalars['Int'];
}>;


export type CancelPaymentMutation = { __typename?: 'Mutation', cancelPayment: { __typename?: 'Payment', id: number } };

export type AllSettingsFieldsFragment = { __typename?: 'Setting', id: number, name: string, value?: string | null };

export type SettingByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SettingByIdQuery = { __typename?: 'Query', settingById?: { __typename?: 'Setting', id: number, name: string, value?: string | null } | null };

export type SettingByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type SettingByNameQuery = { __typename?: 'Query', settingByName?: { __typename?: 'Setting', id: number, name: string, value?: string | null } | null };

export type SettingsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
}>;


export type SettingsQuery = { __typename?: 'Query', settings: Array<{ __typename?: 'Setting', id: number, name: string, value?: string | null }> };

export type AllUsersFieldsFragment = { __typename?: 'User', id: number, name?: string | null, email?: string | null, email_verified_at?: any | null, phone?: string | null, blocked: boolean, balance?: number | null, blocked_reason?: string | null, created_at?: any | null, updated_at?: any | null, role: RoleType, telegram_id?: string | null, subscription_type?: string | null, subscriptions_requests_count?: number | null, last_charge_date?: string | null };

export type UserByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserByIdQuery = { __typename?: 'Query', userById?: { __typename?: 'User', id: number, name?: string | null, email?: string | null, email_verified_at?: any | null, phone?: string | null, blocked: boolean, balance?: number | null, blocked_reason?: string | null, created_at?: any | null, updated_at?: any | null, role: RoleType, telegram_id?: string | null, subscription_type?: string | null, subscriptions_requests_count?: number | null, last_charge_date?: string | null } | null };

export type UsersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<OrderByClause> | OrderByClause>;
  filter?: InputMaybe<Array<FilterByClause> | FilterByClause>;
  first?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UserPaginator', paginatorInfo: { __typename?: 'PaginatorInfo', lastPage: number, total: number, perPage: number, count: number }, data: Array<{ __typename?: 'User', id: number, name?: string | null, email?: string | null, email_verified_at?: any | null, phone?: string | null, blocked: boolean, balance?: number | null, blocked_reason?: string | null, created_at?: any | null, updated_at?: any | null, role: RoleType, telegram_id?: string | null, subscription_type?: string | null, subscriptions_requests_count?: number | null, last_charge_date?: string | null }> } };

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: number, name?: string | null, email?: string | null, email_verified_at?: any | null, phone?: string | null, blocked: boolean, balance?: number | null, blocked_reason?: string | null, created_at?: any | null, updated_at?: any | null, role: RoleType, telegram_id?: string | null, subscription_type?: string | null, subscriptions_requests_count?: number | null, last_charge_date?: string | null } | null };

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', upsertUser?: { __typename?: 'User', id: number, name?: string | null, email?: string | null, email_verified_at?: any | null, phone?: string | null, blocked: boolean, balance?: number | null, blocked_reason?: string | null, created_at?: any | null, updated_at?: any | null, role: RoleType, telegram_id?: string | null, subscription_type?: string | null, subscriptions_requests_count?: number | null, last_charge_date?: string | null } | null };

export type SetUserSubscriptionMutationVariables = Exact<{
  userId: Scalars['Int'];
  subscriptionType: Scalars['String'];
}>;


export type SetUserSubscriptionMutation = { __typename?: 'Mutation', setUserSubscription?: { __typename?: 'User', id: number, name?: string | null, email?: string | null, email_verified_at?: any | null, phone?: string | null, blocked: boolean, balance?: number | null, blocked_reason?: string | null, created_at?: any | null, updated_at?: any | null, role: RoleType, telegram_id?: string | null, subscription_type?: string | null, subscriptions_requests_count?: number | null, last_charge_date?: string | null } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'User', id: number } | null };

export const AllAiProviderAccountFieldsFragmentDoc = `
    fragment allAiProviderAccountFields on AiProviderAccount {
  id
  name
  login
  limit
  blocked
  blocked_reason
  ai_provider_id
  current_spend
  provider {
    id
    name
  }
  created_at
}
    `;
export const AllAiProviderFieldsFragmentDoc = `
    fragment allAiProviderFields on AiProvider {
  id
  name
  description
  created_at
}
    `;
export const AllAiRequestsFieldsFragmentDoc = `
    fragment allAiRequestsFields on AiRequest {
  id
  user_id
  user {
    id
    email
    name
  }
  ai {
    id
    name
    model
    type
    description
    content
    blocked
  }
  ai_provider_account_id
  tokens_cost_rub
}
    `;
export const AllAiFieldsFragmentDoc = `
    fragment allAiFields on Ai {
  id
  name
  model
  type
  token_cost
  description
  content
  blocked
  provider {
    id
    name
  }
  created_at
}
    `;
export const TokenResponseFragmentDoc = `
    fragment tokenResponse on TokensResponse {
  access_token
  refresh_token
}
    `;
export const AllSettingsFieldsFragmentDoc = `
    fragment allSettingsFields on Setting {
  id
  name
  value
}
    `;
export const AllUsersFieldsFragmentDoc = `
    fragment allUsersFields on User {
  id
  name
  email
  email_verified_at
  phone
  blocked
  balance
  blocked_reason
  created_at
  updated_at
  role
  telegram_id
  subscription_type
  subscriptions_requests_count
  last_charge_date
}
    `;
export const AiProviderAccountsDocument = `
    query aiProviderAccounts($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  aiProviderAccounts(
    orderBy: $orderBy
    filter: $filter
    first: $first
    page: $page
  ) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allAiProviderAccountFields
    }
  }
}
    ${AllAiProviderAccountFieldsFragmentDoc}`;
export const useAiProviderAccountsQuery = <
      TData = AiProviderAccountsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AiProviderAccountsQueryVariables,
      options?: UseQueryOptions<AiProviderAccountsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiProviderAccountsQuery, TError, TData>(
      variables === undefined ? ['aiProviderAccounts'] : ['aiProviderAccounts', variables],
      fetcher<AiProviderAccountsQuery, AiProviderAccountsQueryVariables>(client, AiProviderAccountsDocument, variables, headers),
      options
    );
export const AiProviderAccountByIdDocument = `
    query aiProviderAccountById($id: String!) {
  aiProviderAccountById: aiProviderAccounts(
    filter: [{column: "id", value: $id}]
    first: 1
    page: 1
  ) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allAiProviderAccountFields
      api_key
    }
  }
}
    ${AllAiProviderAccountFieldsFragmentDoc}`;
export const useAiProviderAccountByIdQuery = <
      TData = AiProviderAccountByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: AiProviderAccountByIdQueryVariables,
      options?: UseQueryOptions<AiProviderAccountByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiProviderAccountByIdQuery, TError, TData>(
      ['aiProviderAccountById', variables],
      fetcher<AiProviderAccountByIdQuery, AiProviderAccountByIdQueryVariables>(client, AiProviderAccountByIdDocument, variables, headers),
      options
    );
export const AiProviderAccountUsagesDocument = `
    query aiProviderAccountUsages($id: String!, $filter: [FilterByClause!], $limit: Int = 30) {
  aiProviderAccountUsages: aiProviderAccounts(
    filter: [{column: "id", value: $id}]
    first: 1
    page: 1
  ) {
    data {
      usages(filter: $filter, limit: $limit) {
        id
        month
        year
        limit
        spend
        notified
      }
    }
  }
}
    `;
export const useAiProviderAccountUsagesQuery = <
      TData = AiProviderAccountUsagesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: AiProviderAccountUsagesQueryVariables,
      options?: UseQueryOptions<AiProviderAccountUsagesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiProviderAccountUsagesQuery, TError, TData>(
      ['aiProviderAccountUsages', variables],
      fetcher<AiProviderAccountUsagesQuery, AiProviderAccountUsagesQueryVariables>(client, AiProviderAccountUsagesDocument, variables, headers),
      options
    );
export const CreateAiProviderAccountDocument = `
    mutation createAiProviderAccount($input: AiProviderAccountInput!) {
  createAiProviderAccount: upsertAiProviderAccount(input: $input) {
    id
  }
}
    `;
export const useCreateAiProviderAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateAiProviderAccountMutation, TError, CreateAiProviderAccountMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateAiProviderAccountMutation, TError, CreateAiProviderAccountMutationVariables, TContext>(
      ['createAiProviderAccount'],
      (variables?: CreateAiProviderAccountMutationVariables) => fetcher<CreateAiProviderAccountMutation, CreateAiProviderAccountMutationVariables>(client, CreateAiProviderAccountDocument, variables, headers)(),
      options
    );
export const UpdateAiProviderAccountDocument = `
    mutation updateAiProviderAccount($input: AiProviderAccountInput!) {
  upsertAiProviderAccount(input: $input) {
    id
  }
}
    `;
export const useUpdateAiProviderAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateAiProviderAccountMutation, TError, UpdateAiProviderAccountMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateAiProviderAccountMutation, TError, UpdateAiProviderAccountMutationVariables, TContext>(
      ['updateAiProviderAccount'],
      (variables?: UpdateAiProviderAccountMutationVariables) => fetcher<UpdateAiProviderAccountMutation, UpdateAiProviderAccountMutationVariables>(client, UpdateAiProviderAccountDocument, variables, headers)(),
      options
    );
export const DeleteAiProviderAccountDocument = `
    mutation deleteAiProviderAccount($id: Int!) {
  deleteAiProviderAccount(id: $id) {
    id
  }
}
    `;
export const useDeleteAiProviderAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteAiProviderAccountMutation, TError, DeleteAiProviderAccountMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteAiProviderAccountMutation, TError, DeleteAiProviderAccountMutationVariables, TContext>(
      ['deleteAiProviderAccount'],
      (variables?: DeleteAiProviderAccountMutationVariables) => fetcher<DeleteAiProviderAccountMutation, DeleteAiProviderAccountMutationVariables>(client, DeleteAiProviderAccountDocument, variables, headers)(),
      options
    );
export const AiProvidersDocument = `
    query aiProviders($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  aiProviders(orderBy: $orderBy, filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allAiProviderFields
    }
  }
}
    ${AllAiProviderFieldsFragmentDoc}`;
export const useAiProvidersQuery = <
      TData = AiProvidersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AiProvidersQueryVariables,
      options?: UseQueryOptions<AiProvidersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiProvidersQuery, TError, TData>(
      variables === undefined ? ['aiProviders'] : ['aiProviders', variables],
      fetcher<AiProvidersQuery, AiProvidersQueryVariables>(client, AiProvidersDocument, variables, headers),
      options
    );
export const AiProvidersSelectDocument = `
    query aiProvidersSelect($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  aiProvidersSelect: aiProviders(
    orderBy: $orderBy
    filter: $filter
    first: $first
    page: $page
  ) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      id
      name
    }
  }
}
    `;
export const useAiProvidersSelectQuery = <
      TData = AiProvidersSelectQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AiProvidersSelectQueryVariables,
      options?: UseQueryOptions<AiProvidersSelectQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiProvidersSelectQuery, TError, TData>(
      variables === undefined ? ['aiProvidersSelect'] : ['aiProvidersSelect', variables],
      fetcher<AiProvidersSelectQuery, AiProvidersSelectQueryVariables>(client, AiProvidersSelectDocument, variables, headers),
      options
    );
export const AiProviderByIdDocument = `
    query aiProviderById($id: String!) {
  aiProviderById: aiProviders(
    filter: [{column: "id", value: $id}]
    first: 1
    page: 1
  ) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allAiProviderFields
      accounts {
        id
        name
      }
      ais {
        id
        name
      }
    }
  }
}
    ${AllAiProviderFieldsFragmentDoc}`;
export const useAiProviderByIdQuery = <
      TData = AiProviderByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: AiProviderByIdQueryVariables,
      options?: UseQueryOptions<AiProviderByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiProviderByIdQuery, TError, TData>(
      ['aiProviderById', variables],
      fetcher<AiProviderByIdQuery, AiProviderByIdQueryVariables>(client, AiProviderByIdDocument, variables, headers),
      options
    );
export const UpdateAiProviderDocument = `
    mutation updateAiProvider($input: AiProviderInput!) {
  updateAiProvider(input: $input) {
    ...allAiProviderFields
  }
}
    ${AllAiProviderFieldsFragmentDoc}`;
export const useUpdateAiProviderMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateAiProviderMutation, TError, UpdateAiProviderMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateAiProviderMutation, TError, UpdateAiProviderMutationVariables, TContext>(
      ['updateAiProvider'],
      (variables?: UpdateAiProviderMutationVariables) => fetcher<UpdateAiProviderMutation, UpdateAiProviderMutationVariables>(client, UpdateAiProviderDocument, variables, headers)(),
      options
    );
export const AiRequestsDocument = `
    query aiRequests($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  aiRequests(orderBy: $orderBy, filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allAiRequestsFields
    }
  }
}
    ${AllAiRequestsFieldsFragmentDoc}`;
export const useAiRequestsQuery = <
      TData = AiRequestsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AiRequestsQueryVariables,
      options?: UseQueryOptions<AiRequestsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiRequestsQuery, TError, TData>(
      variables === undefined ? ['aiRequests'] : ['aiRequests', variables],
      fetcher<AiRequestsQuery, AiRequestsQueryVariables>(client, AiRequestsDocument, variables, headers),
      options
    );
export const AiRequestByIdDocument = `
    query aiRequestById($id: String!) {
  aiRequestById: aiRequests(
    filter: [{column: "id", value: $id}]
    first: 1
    page: 1
  ) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allAiRequestsFields
    }
  }
}
    ${AllAiRequestsFieldsFragmentDoc}`;
export const useAiRequestByIdQuery = <
      TData = AiRequestByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: AiRequestByIdQueryVariables,
      options?: UseQueryOptions<AiRequestByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiRequestByIdQuery, TError, TData>(
      ['aiRequestById', variables],
      fetcher<AiRequestByIdQuery, AiRequestByIdQueryVariables>(client, AiRequestByIdDocument, variables, headers),
      options
    );
export const AiRequestPayloadByIdDocument = `
    query aiRequestPayloadById($id: String!) {
  aiRequestPayloadById: aiRequests(
    filter: [{column: "id", value: $id}]
    first: 1
    page: 1
  ) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      input
      output
    }
  }
}
    `;
export const useAiRequestPayloadByIdQuery = <
      TData = AiRequestPayloadByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: AiRequestPayloadByIdQueryVariables,
      options?: UseQueryOptions<AiRequestPayloadByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AiRequestPayloadByIdQuery, TError, TData>(
      ['aiRequestPayloadById', variables],
      fetcher<AiRequestPayloadByIdQuery, AiRequestPayloadByIdQueryVariables>(client, AiRequestPayloadByIdDocument, variables, headers),
      options
    );
export const AisDocument = `
    query ais($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  ais(orderBy: $orderBy, filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allAiFields
    }
  }
}
    ${AllAiFieldsFragmentDoc}`;
export const useAisQuery = <
      TData = AisQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: AisQueryVariables,
      options?: UseQueryOptions<AisQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<AisQuery, TError, TData>(
      variables === undefined ? ['ais'] : ['ais', variables],
      fetcher<AisQuery, AisQueryVariables>(client, AisDocument, variables, headers),
      options
    );
export const UpdateAiDocument = `
    mutation updateAi($input: AiInput!) {
  updateAi(input: $input) {
    id
  }
}
    `;
export const useUpdateAiMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateAiMutation, TError, UpdateAiMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateAiMutation, TError, UpdateAiMutationVariables, TContext>(
      ['updateAi'],
      (variables?: UpdateAiMutationVariables) => fetcher<UpdateAiMutation, UpdateAiMutationVariables>(client, UpdateAiDocument, variables, headers)(),
      options
    );
export const LoginDocument = `
    mutation login($input: LoginInput!) {
  login(input: $input) {
    ...tokenResponse
  }
}
    ${TokenResponseFragmentDoc}`;
export const useLoginMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LoginMutation, TError, LoginMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<LoginMutation, TError, LoginMutationVariables, TContext>(
      ['login'],
      (variables?: LoginMutationVariables) => fetcher<LoginMutation, LoginMutationVariables>(client, LoginDocument, variables, headers)(),
      options
    );
export const RegisterDocument = `
    mutation register($input: RegisterInput!) {
  register(input: $input) {
    status
    wait
  }
}
    `;
export const useRegisterMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RegisterMutation, TError, RegisterMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RegisterMutation, TError, RegisterMutationVariables, TContext>(
      ['register'],
      (variables?: RegisterMutationVariables) => fetcher<RegisterMutation, RegisterMutationVariables>(client, RegisterDocument, variables, headers)(),
      options
    );
export const ConfirmRegisterDocument = `
    mutation confirmRegister($input: ConfirmRegisterInput!) {
  confirmRegister(input: $input) {
    ...tokenResponse
  }
}
    ${TokenResponseFragmentDoc}`;
export const useConfirmRegisterMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ConfirmRegisterMutation, TError, ConfirmRegisterMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ConfirmRegisterMutation, TError, ConfirmRegisterMutationVariables, TContext>(
      ['confirmRegister'],
      (variables?: ConfirmRegisterMutationVariables) => fetcher<ConfirmRegisterMutation, ConfirmRegisterMutationVariables>(client, ConfirmRegisterDocument, variables, headers)(),
      options
    );
export const ResetPasswordDocument = `
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    status
    wait
  }
}
    `;
export const useResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ResetPasswordMutation, TError, ResetPasswordMutationVariables, TContext>(
      ['resetPassword'],
      (variables?: ResetPasswordMutationVariables) => fetcher<ResetPasswordMutation, ResetPasswordMutationVariables>(client, ResetPasswordDocument, variables, headers)(),
      options
    );
export const ConfirmResetPasswordDocument = `
    mutation confirmResetPassword($input: ConfirmResetPasswordInput!) {
  confirmResetPassword(input: $input) {
    ...tokenResponse
  }
}
    ${TokenResponseFragmentDoc}`;
export const useConfirmResetPasswordMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ConfirmResetPasswordMutation, TError, ConfirmResetPasswordMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ConfirmResetPasswordMutation, TError, ConfirmResetPasswordMutationVariables, TContext>(
      ['confirmResetPassword'],
      (variables?: ConfirmResetPasswordMutationVariables) => fetcher<ConfirmResetPasswordMutation, ConfirmResetPasswordMutationVariables>(client, ConfirmResetPasswordDocument, variables, headers)(),
      options
    );
export const BalanceLogsDocument = `
    query balanceLogs($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  balanceLogs(orderBy: $orderBy, filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      lastPage
      total
      perPage
    }
    data {
      id
      amount
      balance_before
      action
      description
      user_id
      user {
        email
        name
      }
      payment_id
      updated_at
      created_at
    }
  }
}
    `;
export const useBalanceLogsQuery = <
      TData = BalanceLogsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: BalanceLogsQueryVariables,
      options?: UseQueryOptions<BalanceLogsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<BalanceLogsQuery, TError, TData>(
      variables === undefined ? ['balanceLogs'] : ['balanceLogs', variables],
      fetcher<BalanceLogsQuery, BalanceLogsQueryVariables>(client, BalanceLogsDocument, variables, headers),
      options
    );
export const ChangeUserBalanceDocument = `
    mutation changeUserBalance($id: Int!, $amount: Float) {
  changeUserBalance(id: $id, amount: $amount) {
    balance
  }
}
    `;
export const useChangeUserBalanceMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ChangeUserBalanceMutation, TError, ChangeUserBalanceMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ChangeUserBalanceMutation, TError, ChangeUserBalanceMutationVariables, TContext>(
      ['changeUserBalance'],
      (variables?: ChangeUserBalanceMutationVariables) => fetcher<ChangeUserBalanceMutation, ChangeUserBalanceMutationVariables>(client, ChangeUserBalanceDocument, variables, headers)(),
      options
    );
export const MeDocument = `
    query me {
  me {
    id
    name
    role
  }
}
    `;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['me'] : ['me', variables],
      fetcher<MeQuery, MeQueryVariables>(client, MeDocument, variables, headers),
      options
    );
export const PaymentsDocument = `
    query payments($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  payments(orderBy: $orderBy, filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      lastPage
      total
      perPage
    }
    data {
      amount
      created_at
      description
      id
      status
      url
      user {
        email
        name
      }
      updated_at
      user_id
    }
  }
}
    `;
export const usePaymentsQuery = <
      TData = PaymentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: PaymentsQueryVariables,
      options?: UseQueryOptions<PaymentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<PaymentsQuery, TError, TData>(
      variables === undefined ? ['payments'] : ['payments', variables],
      fetcher<PaymentsQuery, PaymentsQueryVariables>(client, PaymentsDocument, variables, headers),
      options
    );
export const CreatePaymentForUserDocument = `
    mutation createPaymentForUser($input: PaymentAdminInput!) {
  createPaymentForUser(input: $input) {
    url
  }
}
    `;
export const useCreatePaymentForUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatePaymentForUserMutation, TError, CreatePaymentForUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatePaymentForUserMutation, TError, CreatePaymentForUserMutationVariables, TContext>(
      ['createPaymentForUser'],
      (variables?: CreatePaymentForUserMutationVariables) => fetcher<CreatePaymentForUserMutation, CreatePaymentForUserMutationVariables>(client, CreatePaymentForUserDocument, variables, headers)(),
      options
    );
export const CreatePaymentDocument = `
    mutation createPayment($input: PaymentPayloadInput!) {
  createPayment(input: $input) {
    url
  }
}
    `;
export const useCreatePaymentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreatePaymentMutation, TError, CreatePaymentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreatePaymentMutation, TError, CreatePaymentMutationVariables, TContext>(
      ['createPayment'],
      (variables?: CreatePaymentMutationVariables) => fetcher<CreatePaymentMutation, CreatePaymentMutationVariables>(client, CreatePaymentDocument, variables, headers)(),
      options
    );
export const CancelPaymentDocument = `
    mutation cancelPayment($payment_id: Int!) {
  cancelPayment(payment_id: $payment_id) {
    id
  }
}
    `;
export const useCancelPaymentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CancelPaymentMutation, TError, CancelPaymentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CancelPaymentMutation, TError, CancelPaymentMutationVariables, TContext>(
      ['cancelPayment'],
      (variables?: CancelPaymentMutationVariables) => fetcher<CancelPaymentMutation, CancelPaymentMutationVariables>(client, CancelPaymentDocument, variables, headers)(),
      options
    );
export const SettingByIdDocument = `
    query settingById($id: Int!) {
  settingById(id: $id) {
    ...allSettingsFields
  }
}
    ${AllSettingsFieldsFragmentDoc}`;
export const useSettingByIdQuery = <
      TData = SettingByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SettingByIdQueryVariables,
      options?: UseQueryOptions<SettingByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SettingByIdQuery, TError, TData>(
      ['settingById', variables],
      fetcher<SettingByIdQuery, SettingByIdQueryVariables>(client, SettingByIdDocument, variables, headers),
      options
    );
export const SettingByNameDocument = `
    query settingByName($name: String!) {
  settingByName(name: $name) {
    ...allSettingsFields
  }
}
    ${AllSettingsFieldsFragmentDoc}`;
export const useSettingByNameQuery = <
      TData = SettingByNameQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: SettingByNameQueryVariables,
      options?: UseQueryOptions<SettingByNameQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SettingByNameQuery, TError, TData>(
      ['settingByName', variables],
      fetcher<SettingByNameQuery, SettingByNameQueryVariables>(client, SettingByNameDocument, variables, headers),
      options
    );
export const SettingsDocument = `
    query settings($orderBy: [OrderByClause!], $filter: [FilterByClause!]) {
  settings(orderBy: $orderBy, filter: $filter) {
    ...allSettingsFields
  }
}
    ${AllSettingsFieldsFragmentDoc}`;
export const useSettingsQuery = <
      TData = SettingsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SettingsQueryVariables,
      options?: UseQueryOptions<SettingsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SettingsQuery, TError, TData>(
      variables === undefined ? ['settings'] : ['settings', variables],
      fetcher<SettingsQuery, SettingsQueryVariables>(client, SettingsDocument, variables, headers),
      options
    );
export const UserByIdDocument = `
    query userById($id: Int!) {
  userById(id: $id) {
    ...allUsersFields
  }
}
    ${AllUsersFieldsFragmentDoc}`;
export const useUserByIdQuery = <
      TData = UserByIdQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: UserByIdQueryVariables,
      options?: UseQueryOptions<UserByIdQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UserByIdQuery, TError, TData>(
      ['userById', variables],
      fetcher<UserByIdQuery, UserByIdQueryVariables>(client, UserByIdDocument, variables, headers),
      options
    );
export const UsersDocument = `
    query users($orderBy: [OrderByClause!], $filter: [FilterByClause!], $first: Int = 30, $page: Int) {
  users(orderBy: $orderBy, filter: $filter, first: $first, page: $page) {
    paginatorInfo {
      lastPage
      total
      perPage
      count
    }
    data {
      ...allUsersFields
    }
  }
}
    ${AllUsersFieldsFragmentDoc}`;
export const useUsersQuery = <
      TData = UsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: UsersQueryVariables,
      options?: UseQueryOptions<UsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UsersQuery, TError, TData>(
      variables === undefined ? ['users'] : ['users', variables],
      fetcher<UsersQuery, UsersQueryVariables>(client, UsersDocument, variables, headers),
      options
    );
export const CreateUserDocument = `
    mutation createUser($input: UserInput!) {
  createUser: upsertUser(input: $input) {
    ...allUsersFields
  }
}
    ${AllUsersFieldsFragmentDoc}`;
export const useCreateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
      ['createUser'],
      (variables?: CreateUserMutationVariables) => fetcher<CreateUserMutation, CreateUserMutationVariables>(client, CreateUserDocument, variables, headers)(),
      options
    );
export const UpdateUserDocument = `
    mutation updateUser($input: UserInput!) {
  upsertUser(input: $input) {
    ...allUsersFields
  }
}
    ${AllUsersFieldsFragmentDoc}`;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['updateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers)(),
      options
    );
export const SetUserSubscriptionDocument = `
    mutation setUserSubscription($userId: Int!, $subscriptionType: String!) {
  setUserSubscription(userId: $userId, subscriptionType: $subscriptionType) {
    ...allUsersFields
  }
}
    ${AllUsersFieldsFragmentDoc}`;
export const useSetUserSubscriptionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SetUserSubscriptionMutation, TError, SetUserSubscriptionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<SetUserSubscriptionMutation, TError, SetUserSubscriptionMutationVariables, TContext>(
      ['setUserSubscription'],
      (variables?: SetUserSubscriptionMutationVariables) => fetcher<SetUserSubscriptionMutation, SetUserSubscriptionMutationVariables>(client, SetUserSubscriptionDocument, variables, headers)(),
      options
    );
export const DeleteUserDocument = `
    mutation deleteUser($id: Int!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export const useDeleteUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteUserMutation, TError, DeleteUserMutationVariables, TContext>(
      ['deleteUser'],
      (variables?: DeleteUserMutationVariables) => fetcher<DeleteUserMutation, DeleteUserMutationVariables>(client, DeleteUserDocument, variables, headers)(),
      options
    );