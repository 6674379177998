import React, { PropsWithChildren } from "react";
import { ThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles";

const colors = {
  primary: "#02B381",
  primaryActive: "#009f72"
};

const tableColors = {
  background: "#fff",
  rowHover: "rgba(238,238,238, .3)"
};

const theme = createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: "0.4rem 0.4rem 0.4rem 0.4rem"
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: tableColors.background,
          zIndex: 1,
          "& .MuiTableRow-root": {
            zIndex: 1
          },
          "& .MuiTableCell-head": {
            background: tableColors.background,
            zIndex: 1
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:hover .MuiTableCell-body": {
            position: "relative"
          },
          "& .MuiTableRow-root:hover .MuiTableCell-body:after": {
            content: "''",
            width: "100%",
            height: "100%",
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            background: tableColors.rowHover,
            pointerEvents: "none"
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          position: "relative",
          zIndex: 0
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          padding: 2,
          backgroundColor: tableColors.background
        },
        head: {
          paddingLeft: 8
        },
        body: {
          padding: 8
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      }
    },

    MuiInputLabel: {
      defaultProps: {
        shrink: true
      },
      styleOverrides: {
        root: {
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 24,
          width: 24
        }
      }
    },
    MuiInput: {
      defaultProps: {
        size: "small"
      }
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          size: "small"
        },
        InputLabelProps: {
          shrink: true
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        size: "small"
      }
    },
    MuiSelect: {
      defaultProps: {
        size: "small"
      }
    },
    MuiMenu: {
      defaultProps: {
        style: {
          height: "300px"
        }
      }
    }
  },
  palette: {
    primary: {
      main: colors.primary
    }
  }
});

export const CustomThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
