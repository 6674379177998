import clsx from "clsx";
import React, { forwardRef } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { Text, TextProps } from "../Text";

export type Props = LinkProps & {
  textProps?: Omit<TextProps, "children">;
};

export const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ children, className, textProps, ...props }, ref): React.ReactElement => {
    const inner =
      typeof children === "string" ? (
        <Text component='span' {...textProps}>
          {children}
        </Text>
      ) : (
        children
      );

    return (
      <RouterLink ref={ref} className={clsx("hover:underline inline-flex", className)} {...props}>
        {inner}
      </RouterLink>
    );
  }
);

Link.displayName = "Link";
